import React from 'react';
import { useConfigService } from '@thd-nucleus/experience-context';
import './NotificationSection.scss';
import { getAccrualData, isObjectEmpty } from '../../../common/utils/cardUtils';
import {
  StatusUnavailable,
  NotificationSkeleton,
} from '../../../common/StatusUnavailableNotifications/StatusUnavailable';
import {
  ProgressPerk,
  PerkWithStaticIcon,
  ProCreditCard,
  PreferredUnlocked,
  AvailableOffers,
  PaintMemberProducts,
  ExpiringOffers,
} from './Notifications';
import * as Constants from '../../../core/Constants';
import { EliteSupportLine } from './Notifications/OfferNotifications';

export const showingTileNotifications = ({ isAdmin, userData, availablePerksData }) => {
  const { perkSummary } = userData || {};
  const { activePerksCount } = perkSummary || {};
  const accrualData = getAccrualData(perkSummary);
  const proXtraCCProgram = perkSummary?.pxccUserCategory === 'Enrolled';
  const showRenderPerks = isAdmin
    && !isObjectEmpty(accrualData)
    // enrolledInPerksProgram &&
    && (perkSummary?.unclaimedPerksCount > 0
      || (!accrualData?.finalTierPerkAchieved && proXtraCCProgram)
      || accrualData?.finalTierPerkAchieved
      || availablePerksData !== undefined
      || activePerksCount);

  return {
    RenderPerksProgress: showRenderPerks,
    EnrollmentPrograms: true,
  };
};

export const prioritizedListNotifications = ({
  showingTile,
  userData,
  availablePerksData,
  isLoadingPerks,
  refreshAvailablePerks,
  refreshAvailableOffers,
  availablePerksError,
}) => {
  const {
    customerInfo, currentYearMemberships, perkSummary, userProfile, proTiers
  } = userData || {};
  const proXtraCCProgram = perkSummary?.pxccUserCategory === 'Enrolled';
  const { activePerksCount, availableActiveOffers } = perkSummary || {};
  const accrualData = getAccrualData(perkSummary);
  let currentProPaintRewards;
  const currentPaintLevel = customerInfo?.paintMembershipLevel
    ? customerInfo?.paintMembershipLevel
    : 'Member';
  const phoneTagName = 'eliteCallCenter';
  const currentTierName = proTiers?.currentTierName;
  const benefitTags = proTiers?.benefitTags;
  const paintSpend = () => {
    if (currentYearMemberships) {
      const mem = currentYearMemberships?.currentYearMembershipInfo;
      mem.map((current) => {
        if (current.programId === '2') {
          currentProPaintRewards = current;
        }
        return currentProPaintRewards;
      });
    }
  };

  const RenderPerksProgress = () => {
    const finalTierPerkAchieved = accrualData.finalTierPerkAchieved;
    const { unclaimedPerksCount } = perkSummary || {};
    const data = availablePerksData;
    const error = !availablePerksData || isObjectEmpty(availablePerksData) || availablePerksError;
    const analyticsMessage = Constants.analytics.ANALYTICS_MESSAGE;
    const analyticsLinks = Constants.analytics.ANALYTICS_LINKS;
    const { isPacDrawersEnabled } = ['1', 1].includes(useConfigService('ENABLE_LOYALTY_PAC_DRAWERS'));
    if (isLoadingPerks) {
      return <NotificationSkeleton />;
    }

    // State 1
    if (finalTierPerkAchieved && !activePerksCount && unclaimedPerksCount < 1) {
      return (
        <PerkWithStaticIcon
          sublabel={`${userProfile.name.firstName} ${userProfile.name.lastName}`}
          analyticsMessage={analyticsMessage.WELL_DONE}
          analyticsLinks={analyticsLinks.PERKSLINK}
        />
      );
    }

    // State 2
    if (activePerksCount && !isLoadingPerks && data?.expiringPerks > 0) {
      return (
        <PerkWithStaticIcon
          label={`${data?.expiringPerks} Perks Expiring Soon`}
          analyticsMessage={analyticsMessage.Perks_Expiring_Soon}
          refLink="/myaccount/proXtraRewards?drawerList=perk"
          analyticsLinks={analyticsLinks.VIEW_PERKS}
        />
      );
    }

    // State 3
    if (unclaimedPerksCount > 0) {
      const refLink = isPacDrawersEnabled ? '/myaccount/proXtraRewards?drawerList=perk' : '/myaccount/proXtraPerks';
      return (
        <PerkWithStaticIcon
          label="New Perk Unlocked"
          imgUrl="badge-unlocked.svg"
          linkText="Choose Perk"
          analyticsMessage={analyticsMessage.New_Perk_Unlocked}
          analyticsLinks={analyticsLinks.CHOOSE_PERK}
          refLink={refLink}
        />
      );
    }

    // State 4
    if (activePerksCount && !isLoadingPerks && data?.availablePerks > 0) {
      return (
        <PerkWithStaticIcon
          label={`${data?.availablePerks} Perks Available`}
          analyticsMessage={analyticsMessage.Perks_Available}
          analyticsLinks={analyticsLinks.VIEW_PERKS}
          refLink="/myaccount/proXtraRewards?drawerList=perk"
        />
      );
    }

    if (!finalTierPerkAchieved) {
      if (proXtraCCProgram) {
        // State 5
        return <ProCreditCard perkSummary={perkSummary} />;
      }
      // State 6
      return <ProgressPerk perkSummary={perkSummary} />;
    }

    return (
      <StatusUnavailable
        error={error}
        isLoadingPerks={isLoadingPerks}
        refreshFn={refreshAvailablePerks}
      />
    );
  };

  const EnrollmentPrograms = () => {
    paintSpend();
    const data = availablePerksData;

    if (isLoadingPerks) {
      return <NotificationSkeleton />;
    }

    if (!isLoadingPerks && data) {
      // State 1
      if (data?.expiringOffers > 0) {
        return <ExpiringOffers expiringOffersCount={data.expiringOffers} />;
      }
      // State 3
      if (data?.availableOffers > 0) {
        return <AvailableOffers availableOffersCount={data.availableOffers} />;
      }
    }
    // State 4
    const isAtLeastElite = currentTierName.toUpperCase() === 'ELITE' || currentTierName.toUpperCase() === 'VIP';
    if (!availableActiveOffers && isAtLeastElite) {
      const phoneTag = benefitTags?.find((tag) => {
        return tag.tagName === phoneTagName;
      });
      return <EliteSupportLine phoneTag={phoneTag} />;
    }

    // State 5
    if (!availableActiveOffers && customerInfo?.enrolledInPreferredPricing) {
      return <PreferredUnlocked />;
    }

    // State 6
    if (!availableActiveOffers && !customerInfo?.enrolledInPreferredPricing) {
      return <PaintMemberProducts currentPaintLevel={currentPaintLevel} />;
    }

    return (
      <StatusUnavailable
        isLoadingPerks={isLoadingPerks}
        refreshFn={refreshAvailableOffers}
        isPerks={false}
      />
    );
  };

  const prioritizedList = [
    customerInfo?.enrolledInPerksProgram && showingTile.RenderPerksProgress && (
      <RenderPerksProgress />
    ),
    // showingTile.AvailableOffers && <AvailableOffers />,
    showingTile.EnrollmentPrograms && <EnrollmentPrograms />,
  ];

  return prioritizedList;
};

const NotificationSection = ({ tiles }) => {
  const DisplayTiles = () => tiles;

  return <DisplayTiles />;
};

export const exportsForTesting = {
  ProgressPerk,
  PerkWithStaticIcon,
  PreferredUnlocked,
  ProCreditCard,
  PaintMemberProducts,
  StatusUnavailable,
  AvailableOffers,
};

export default NotificationSection;
